import {Injectable} from '@angular/core'
import {combineLatest, Observable, of} from 'rxjs'
import {catchError, switchMap, take} from 'rxjs/operators'
import {AuthService} from '../auth/services/auth.service'
import { HttpClient } from '@angular/common/http'
import {UserPermission} from '../model/user-permission'

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    constructor(private authService: AuthService, private httpClient: HttpClient) {

    }

    private urlAndSession = combineLatest([this.authService.onAuthDomain, this.authService.onUserSession]).pipe(
        take(1),
        switchMap((values): Observable<[object, string]> => {
            const authDomain = values[0]
            const session = values[1]
            const options = {
                headers: {
                    Authorization: 'Bearer ' + session.accessToken.jwtToken,
                    'id-token': session.idToken.jwtToken,
                    Accept: 'application/json',
                },
            }
            const url = 'https://api.' + authDomain
            return of([options, url])
        })
    )

    permissionsFor = (account: string, userId: string): Observable<UserPermission> => {
        return this.urlAndSession.pipe(
            switchMap(([options, url]) => {
                return this.httpClient.get<UserPermission>(`${url}/auth/admin/${account}/permissions/${userId}`, options).pipe(
                    catchError((err): Observable<UserPermission> => {
                        return of({clients: [], distributors: []})
                    }),
                )
            }),
        )
    }

    updatePermissionsFor = (account: string, userId: string, permissions: UserPermission): Observable<any> => {
        return this.urlAndSession.pipe(
            switchMap(([options, url]) => {
                return this.httpClient.post(`${url}/auth/admin/${account}/permissions/${userId}`, permissions, options)
            }),
        )
    }

    deleteAll = (): Observable<any> => {
        return this.urlAndSession.pipe(
            switchMap(([options, url]) => {
                return this.httpClient.delete(`${url}/auth/all`, options)
            }),
        )
    }
}
