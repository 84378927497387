import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ActivationEnd, Router, RouterEvent } from '@angular/router';

import { AuthService } from './auth/services/auth.service';
import { DateTime } from 'luxon';
import { AdminGuard } from './guards/admin-guard.service';
import { combineLatest } from 'rxjs';
import { CurrentUserService } from './services/current-user.service';
import { KydUserSessionService } from './kyd/services/kyd-user-session.service';
import { QuestionnaireService } from './kyd/services/questionnaire.service';
import { KydRequestService } from './kyd/services/kyd-request.service';
import { DcAlertService } from './ui-kit/dc-alert/dc-alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  navigating: boolean = false;
  loggedInAsInspire: boolean = false;
  loggedInAsDistributor: boolean = false;
  loggedInAsIssuer: boolean = false;
  signedIn: boolean = false;
  user: string = undefined;
  email: string = undefined;
  activeRoute: string;

  closePanels = false;

  appVersion = 'APP_VERSION_PLACEHOLDER';
  appBuildNumber = 'APP_BUILD_NUMBER_PLACEHOLDER';
  appBuildTime = parseInt('APP_BUILD_TIME_PLACEHOLDER', 10) || 1584435059600;
  environment = '';

  appBuildDateString: string;

  constructor(
    private router: Router,
    private overlayContainer: OverlayContainer,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private kydUserSessionService: KydUserSessionService,
    private questionnaireService: QuestionnaireService,
    private adminGuard: AdminGuard,
    private kydRequestService: KydRequestService,
    private dcAlertService: DcAlertService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.activeRoute = event.snapshot.routeConfig.path;
      }
    });



    try {
      if (this.appVersion.startsWith('APP_')) {
        this.appVersion = 'DEV-BUILD';
      }
      if (this.appBuildNumber.startsWith('APP_')) {
        this.appBuildNumber = 'DEV';
      }
      const dateTime = DateTime.fromMillis(this.appBuildTime);
      this.appBuildDateString = dateTime.toLocaleString({
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
      // this.authService.oauthToken.subscribe(token => this.token = token)
    } catch (e) {
      console.error('Failed to determine application version', e);
    }
  }

  ngOnInit() {
    combineLatest([this.authService.onUserSession, this.currentUserService.currentUser]).subscribe(
      ([session, currentUser]) => {
        switch (currentUser.type) {
          case 'inspire':
            this.loggedInAsInspire = true;
            break;
          case 'distributor':
            this.loggedInAsDistributor = true;
            break;
          case 'issuer':
            this.loggedInAsIssuer = true;
            break;
        }

        this.loading = false;

        this.signedIn = session?.isValid();

        this.user = session?.isValid() && session.idToken.payload.name;
        this.email = session?.isValid() && session.idToken.payload.email;

        if (currentUser.type === 'inspire') {
          this.kydRequestService.fetchDirtyRequests().subscribe((dirtyRequests) => {
            const filteredRequests = dirtyRequests.filter((request) => {
              return request.handled <= 0;
            });
            if (filteredRequests.length >= 1) {
              this.dcAlertService.showAlert({
                type: 'warning',
                message: `There are currently ${filteredRequests.length} requests for you to review. Please visit the 'Review Requests' page to view them.`,
              });
            }
          });
        }
      }
    );
    this.authService.onEnvironment.subscribe((environment) => {
      this.environment = environment;
    });
  }

  ngOnDestroy(): void {}

  // navigationInterceptor(event: RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     console.log("nav start");
  //     this.navigating = true;
  //     this.activeRoute = undefined;
  //     this.closePanels = true;
  //   }
  //   if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
  //     console.log("nav end");
  //     this.navigating = false;
  //     this.closePanels = true;
  //   }
  //   if (event instanceof ActivationEnd) {
  //     console.log("nav act end");
  //     this.activeRoute = event.snapshot.routeConfig.path;
  //   }
  // }

  async signOut() {
    this.currentUserService.currentUser.subscribe(async (currentUser) => {
      if (currentUser.type === 'distributor') {
        try {
          const nobodyElseIsEditing = await this.kydUserSessionService.nobodyElseIsEditing();

          if (nobodyElseIsEditing) {
            this.kydUserSessionService.userInactive.next(undefined);
            return;
          } else {
            await this.authService.signOut();
          }
        } catch {
          await this.authService.signOut();
        }
      } else {
        await this.authService.signOut();
      }
    });
  }

  goHome() {
    console.log('app home');
    this.router.navigate(['requests']);
  }
}
