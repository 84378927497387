import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {HOSTNAME} from '../../util/hostname'
import {SettingsResponse} from '../model/settings-responses'

@Injectable()
export class AuthSettingsService {
  constructor(private httpClient: HttpClient) {
  }

  async loadSettings() {
    const settingsUrl = 'https://api.' + HOSTNAME + '/settings/authServers'
    return this.httpClient
      .get(settingsUrl, {
        headers: {
          Accept: 'application/json',
        },
      })
      .toPromise()
      .then(response => {
        return response as SettingsResponse
      })
  }
}

